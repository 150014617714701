import React, { useEffect, useState, useRef } from "react";
import { useSpring, animated } from 'react-spring';

import Navigation from "./components/navigation";

import src from "./assets/images/auch-cool-intro.jpg";
import srcWebp from "./assets/images/auch-cool-intro.webp";
import raboImage from "./assets/images/customers/Levis.svg";
import smarststeuerImage from "./assets/images/customers/smartsteuer.svg";
import sapImage from "./assets/images/customers/SAP.svg";
import rossmannImage from "./assets/images/customers/Rossmann.svg";
import sternburgImage from "./assets/images/customers/Sterni.svg";

import hourIcon from "./assets/images/icons/48h.svg";
import skillIcon from "./assets/images/icons/skill.svg";
import comunityIcon from "./assets/images/icons/projects.svg";
import connectionIcon from "./assets/images/icons/connection.svg";
import deskIcon from "./assets/images/icons/desk.svg";
import coffeeIcon from "./assets/images/icons/coffee.svg";

import clickIcon from "./assets/images/icons/click.svg";
import chatIcon from "./assets/images/icons/chat.svg";
import heartIcon from "./assets/images/icons/herz.svg";

function debounce(func, wait = 5, immediate = true) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default function App() {
  const [showModal, setShowModal] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const auchCool2Ref = useRef(null);
  const auchCool3Ref = useRef(null);
  const [isOpen, setIsOpen] = useState( false );
  const [isBusiness, setIsBusiness] = useState( false );
  const [formSubmitStatus, setFormSubmitStatus] = useState( null );

  const [{ springscrollY }, springsetScrollY] = useSpring(() => ({
    springscrollY: 0
  }));
  const [{ auchCool2 }, setAuchCool2] = useSpring(() => ({
    auchCool2: 0
  }));
  const [{ auchCool3 }, setAuchCool3] = useSpring(() => ({
    auchCool3: 0
  }));

  const _submitContactFormular = (e) => {
    e.preventDefault();
    var formElement = document.getElementById("ac-contact-form");
    var formData = new FormData( formElement );
    var url = 'https://auchcool.de/api.php';
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if( xhr.status === 200 ) {
              setFormSubmitStatus("success");
          } else {
            setFormSubmitStatus("error");
          }
        }
    }
    xhr.open('POST', url, true);
    xhr.send(formData);
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    }
    window.addEventListener("scroll", debounce(handleScroll));
    return () => window.removeEventListener("scroll", debounce(handleScroll));
  }, [debounce]);

  const parallaxLevel = 5;

  if (springsetScrollY && {}.toString.call(springsetScrollY) === '[object Function]') {
    springsetScrollY({ springscrollY: -Math.abs(scrollY) });
  }

  if (auchCool2Ref && auchCool2Ref.current && auchCool2Ref.current.offsetParent) {
    if ((scrollY) >= (auchCool2Ref.current.offsetParent.offsetTop - (window.innerHeight / 2))) {
      setAuchCool2({ auchCool2: -Math.abs(scrollY - (auchCool2Ref.current.offsetParent.offsetTop - (window.innerHeight / 2))) });
    }
  }

  if (auchCool3Ref && auchCool3Ref.current && auchCool3Ref.current.offsetParent) {
    if ((scrollY) >= (auchCool3Ref.current.offsetParent.offsetTop - (window.innerHeight / 2))) {
      setAuchCool3({ auchCool3: -Math.abs(scrollY - (auchCool3Ref.current.offsetParent.offsetTop - (window.innerHeight / 5))) });
    }
  }

  return (
    <div className={`body-wrapper ${isOpen ? 'disable-scrolling' : ""}`}>
      <Navigation isOpen={isOpen} setIsOpen={setIsOpen} />
      <section className="ac-home">
        <animated.div className="App" style={{ transform: springscrollY ? springscrollY.interpolate((o) => `translateY(${o / parallaxLevel}px)`): "" }}>
          <span className="ac-logo">
            <span>Auch</span>
            <span>Cool</span>
          </span>
        </animated.div>
        <div className="ac-home__wrapper">
          <div>
            <h1>
              Du bist Freelancer im Bereich Graphic Design, Creative Conception, Development, UI/UX oder Online Marketing?
              Oder du bist Fotograf, Produktionsagentur, Tonstudio, CGI-Artist?
            </h1>
          </div>
          <div>
            <picture>
                <source srcSet={srcWebp} type="image/webp" />
                <img src={src} title="Werde auch Cool!" alt="Werde auch Cool!" />
            </picture>
          </div>
          <div>
            <h2>Du könntest Auch Cool sein!</h2>
            <a className="ac-btn" href="#anmelden" title="Mehr erfahren!">Mehr erfahren!</a>
          </div>
        </div>
        <ul>
          <li>
            <img src={raboImage} alt="Levis" title="Levis" />
          </li>
          <li>
            <img src={smarststeuerImage} alt="Smartsteuer" title="Smartsteuer" />
          </li>
          <li>
            <img src={sapImage} alt="SAP" title="SAP" />
          </li>
          <li>
            <img src={rossmannImage} alt="Rossmann" title="Rossmann" />
          </li>
          <li>
            <img src={sternburgImage} alt="Sternburg" title="Sternburg" />
          </li>
        </ul>
      </section>

      <section className="ac-introduction" id="anmelden">
        <div className="wrapper grid-2">
          <div>
            <h3 className="badge-headline"><span>01.</span>CREATIVE COOL</h3>
            <p>Colab-Time: Werde Teil des W&S EPIC Creative Pools und arbeite projektweise mit uns auf #lovebrands wie SAP, Sternburg, Rossmann, Levis, RaboDirect und vielen mehr.</p>
            <p>Wer Bock auf mehr hat, kann auch hinein in die coolste Community seit SchülerVZ. Mit Gatherings, Workshops und Sterni.</p>
            <p>Einfach Portfolio hochladen, uns im persönlichen Gespräch kennen lernen und rein in den Pool. Projekte nach außen werden zukünftig nur noch hier vergeben.</p>
          </div>
          <div>
            <ul>
              <li>
                <img src={skillIcon} alt="Skill Icon" title="Skill, Portfolio, Bock, Anspruch" />
              Du bringst mit:<br />Skill, Portfolio,<br /> Bock, Anspruch
            </li>
              <li>
                <img src={hourIcon} alt="Stunden Icon" title="Faire Bezahlung" />
                Du bekommst<br />auch von uns:<br /> Faire Bezahlung<br />innerhalb von 48h
            </li>
              <li>
                <img src={comunityIcon} alt="Projekte Icon" title="Projekte die zu dir passen" />
                Du bekommst von uns:<br /> Projekte, die zu dir passen
            </li>
            </ul>
            <div className="grid-2 ac-action-grid">
              <div>
                <button
                  className="ac-btn"
                  onClick={() => setIsBusiness(false, setShowModal(true))}>Als Freelancer anmelden
                </button>    
              </div>
              <div>
                <button
                  className="ac-btn"
                  onClick={() => setIsBusiness( true, setShowModal(true) )}>Als Unternehmen anmelden
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ac-introduction alternate">
        <span className="ac-postrona">
          <p>#Postrona</p>
        </span>
        <div className="wrapper">
          <h3 className="badge-headline red"><span>02.</span>EPIC OFFICE</h3>
          <div className="grid-2">
            <div>
              <p>
                <b>Wenn dir zuhause die Decke auf den Kopf fällt: AUCH COOLe Menschen haben die Möglichkeit, sich einen Tisch in unserem Office zu sehr moderaten Konditionen zu mieten.</b>
              </p>
              <p>Jeder weiß, dass man zusammen kreativer ist als alleine. Komm ran und arbeite hier. Kühlschrank ist voll. Leute cool. Wenn du auf einem Projekt von uns arbeitest, zahlst du für den Zeitraum außerdem keine Miete!</p>
            </div>
            <div>
              <ul>
                <li>
                  <img src={deskIcon} alt="Desk Icon" title="Desk für 120€ / Monat" />
                  Desk für 120€ / Monat
              </li>
                <li>
                  <img src={coffeeIcon} alt="Coffee Icon" title="inkl. aller Annehmlichkeiten" />
                  inkl. aller Annehmlichkeiten
              </li>
                <li>
                  <img src={connectionIcon} alt="Community Icon" title="Connecte mit Leuten, die AUCH COOL sind" />
                  Connecte mit Leuten, die AUCH COOL sind
              </li>
              </ul>
              <div className="grid-2 ac-action-grid">
                <div>
                  <span>
                    <a className="ac-btn violet" href="/" title="Jetzt als Freelancer anmelden">Jetzt als Freelancer anmelden</a>
                    <p>Um einen Desk im Shared Office anzufragen, musst du Teil von AUCH COOL sein.</p>
                  </span>
                </div>
                <div>
                  <span>
                    <a className="ac-btn red" href="/" title="Desk anfragen">Desk anfragen</a>
                    <p>Du bist schon AUCH COOL Freelancer und willst dir einen Platz im Shared Office ergattern?</p>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ac-why">
        <animated.div
          className="ac-logo"
          style={{ transform: auchCool2 ? auchCool2.interpolate((o) => `translateY(${o / parallaxLevel}px)`) : "" }}
          ref={auchCool2Ref}
        >
          <span>Auch</span>
          <span>Auch</span>
        </animated.div>
        <div className="wrapper">
          <h2>Warum auch ein Shared Office?</h2>
          <p>Remote ist die Zukunft, aber vor allem das Home Office hat im kollaborativen Arbeiten seine Grenzen. Wir glauben, dass zusammen sein (#Beziehung) zu mehr Spaß und besseren Ergebnissen führt – vor allem im kreativen Prozess. Deshalb: Unser rechter, rechter Platz ist frei...</p>
        </div>
      </section>

      <section className="ac-how">
        <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          viewBox="0 0 1679 103" >
          <polygon className="st0" points="0,103.46 1678.5,0 0,0 " />
        </svg>
        <h2>Wie werde ich AUCH COOL?</h2>
        <div className="wrapper">
          <ol>
            <li>
              <div>
                <h3>Anmeldung</h3>
                <p>Du bist in einem der von uns genannten Bereichen tätig? Schon mal cool! Melde dich mit deinen Kontaktdaten an und lade Arbeitsproben / Portfolio hoch.</p>
              </div>
              <div>
                <img src={clickIcon} alt="Click Icon" title="Click Icon" />
              </div>
            </li>
            <li>
              <div>
                <img src={chatIcon} alt="Chat Icon" title="Chat Icon" />
              </div>
              <div>
                <h3>CHEMISTRY MEETING</h3>
                <p>Skills sind das eine, das Miteinander ist uns aber genauso wichtig #peoplefirst. Wir sichten deine Unterlagen und laden dich zum persönlichen Video-Call ein.</p>
              </div>
            </li>
            <li>
              <div>
                <h3>REGISTRIERUNG</h3>
                <p>Passt alles? Fire! Willkommen im Pool, du bist AUCH COOL! Du kriegst deinen Zugang zum Community Tool und 'n T-&nbsp;Shirt! Oh – und Jobs!</p>
              </div>
              <div>
                <img src={heartIcon} alt="Heart Icon" title="Heart Icon" />
              </div>
            </li>
          </ol>
        </div>
      </section>

      <section className="ac-faqs" id="faq">
        <div className="wrapper">
          <h2>Noch Fragen?</h2>
          <ul>
            <li className="ac-faq">
              <input type="checkbox" id="faq-1" />
              <label htmlFor="faq-1" >Wer seid ihr überhaupt?!</label>
              <p>
                Und warum liegt hier eigentlich Stroh?<br/>
                Hinter AUCH COOL verbirgt sich eine der größten inhabergeführten Werbeagenturen Deutschlands: W&S Epic. GmbH. Mit Standorten in Hannover und Berlin und ca. 70 Mitarbeiter*innen sind wir als moderne Full Service Agentur aufgestellt. 
              </p>
            </li>
            <li className="ac-faq">
              <input type="checkbox" id="faq-2" />
              <label htmlFor="faq-2" >Was bringt es mir AUCH COOL zu sein?</label>
              <p>
                Na zuallererst bist du dann mal AUCH COOL – was ja auch schon mal hilft. Zukünftig werden Projekte von uns nur noch über AUCH COOL vergeben. Heißt: Wenn du mit uns arbeiten möchtest, musst du hier rein.<br/>
                Perspektivisch wollen wir hier aber etwas viel Größeres bauen, als das. Freu dich drauf und sei ein Teil davon!
              </p>
            </li>
            <li className="ac-faq">
              <input type="checkbox" id="faq-3" />
              <label htmlFor="faq-3" >Auf was für Projekten werde ich arbeiten?</label>
              <p>Das halten wir #agil. Abhängig davon, was gerade ansteht und was für Skills benötigt werden / du hast, fragen wir dich an. Wenn alles passt, geht’s los!</p>
            </li>
            <li className="ac-faq">
              <input type="checkbox" id="faq-4" />
              <label htmlFor="faq-4" >Wer entscheidet, auf welchen Projekten ich arbeiten werde?</label>
              <p>Wir. Je nachdem, was Timing und Skill angeht, kommen wir auf dich zu, fragen an – und los gehts! Die Projekte werden sich aus unserem Kundenportfolio, sowie Neuanfragen ergeben.</p>
            </li>
            <li className="ac-faq">
              <input type="checkbox" id="faq-5" />
              <label htmlFor="faq-5">Wie lange dauert die Überprüfung meiner Bewerbung?</label>
              <p>Das geht natürlich ruckzuck. Wenn alles passt, sind wir schnell im persönlichen Kontakt und dann können weitere Schritte besprochen werden. Nach Projektabschluss bekommst du dein vereinbartes Honorar innerhalb von 48 Stunden nach Rechnungseingang über einen der üblichen Wege.</p>
            </li>
            <li className="ac-faq">
              <input type="checkbox" id="faq-6" />
              <label htmlFor="faq-6" >Wie werde ich bezahlt?</label>
              <p>Beim Kennenlernen definieren wir Tagessätze und den ganzen Rechtskram vorab, was uns später im Prozess der Zusammenarbeit später beschleunigt. Nach Projektabschluss wirst du über die gängigen Wege bezahlt: Du stellst die Rechnung, wir bezahlen mit €€€.</p>
            </li>
          </ul>
        </div>
      </section>

      <section className="ac-contact" id="contact">
        <animated.div
          className="wrapper"
          style={{ transform: auchCool3 ? auchCool3.interpolate((o) => `translateY(${o / parallaxLevel}px)`) : ""}}
          ref={auchCool3Ref}
        >
          <span className="ac-logo">Cool</span>
        </animated.div>
        <div className="wrapper">
        </div>
        <div className="grid-2 spacing">
          <div>&nbsp;</div>
          <div>
            <h2>Sonst noch was?</h2>
            <p>Wir helfen dir gerne weiter und freuen uns von dir zu hören!</p>
            <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
              viewBox="0 0 1679 103" >
              <polygon className="st0" points="0,103.46 1678.5,0 0,0 " />
            </svg>
          </div>
        </div>
        <div className="grid-2 blue">
          <div>&nbsp;</div>
          <div>
            <form id="ac-contact-form" onSubmit={_submitContactFormular}>
              <input type="hidden" name="formType" value="contact" />
              <div className="grid-2">
                <label>
                  Vorname
                  <input type="text" name="firstname" />
                </label>
                <label>
                  Nachname
                  <input type="text" name="lastname" />
                </label>
              </div>
              <label>
                E-Mail
                <input type="text" name="email" />
              </label>
              <label>
                Nachricht
                <textarea name="message"></textarea>
              </label>
              <button className="ac-btn red" type="submit">Abschicken</button>
            </form>
          </div>
        </div>
        <footer className="ac-footer">
          <div>
            <ul>
              <li>DE</li>
              <li>EN</li>
            </ul>
          </div>
          <div>
            <ul>
              <li>Impressum</li>
              <li>Datenschutz</li>
              <li>Cookies</li>
              <li>Cookies preferences</li>
            </ul>
          </div>
        </footer>
      </section>
      {showModal ?
        <ContactModal setShowModal={setShowModal} isBusiness={isBusiness} setIsBusiness={setIsBusiness} setFormSubmitStatus={setFormSubmitStatus} />
        : ""}
      {formSubmitStatus ?
        <div className={`ac-modal ${formSubmitStatus}`}>
          <div className="ac-modal__wrapper">
            <button className="ac-modal__close" onClick={() => setFormSubmitStatus(null)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </button>
            <p className="ac-modal__title">{formSubmitStatus === "success" ? "Danke für's schreiben!" : "Leider ist etwas schief gelaufen"}</p>
            <p className="ac-modal__message">{formSubmitStatus === "success" ? "Du wirst demnächst von uns hören!" : "Bitte probiere es einfach nochmal!"}</p>
          </div>
        </div>
      : ""}
    </div>
  )
}

function ContactModal({ setShowModal, isBusiness, setIsBusiness, setFormSubmitStatus }) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [link, setLink] = useState("");
  const [showSecondPage, setShowSecondPage] = useState(false);
  const [cv, setCv] = useState(null);
  const [portfolio, setPortfolio] = useState(null);
  const [firmname, setFirmname] = useState("");
  const [message, setMessage] = useState("");

  const _onSubmit = (e) => {
    e.preventDefault();
    var formElement = document.getElementById("finish-submit");
    var formData = new FormData( formElement );
    var url = 'https://auchcool.de/api.php';
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          setShowModal( false );
          if( xhr.status === 200 ) {
              setFormSubmitStatus("success");
          } else {
            setFormSubmitStatus("error");
          }
        }
    }
    xhr.open('POST', url, true);
    xhr.send(formData);
  }

  return (
    <div className="ac-contact-modal">
      <button className="ac-contact-modal__close-btn" onClick={() => setIsBusiness(false, setShowModal(false) )}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
      </button>
      <form id="finish-submit" className={isBusiness ? "isBusiness" : ""} onSubmit={_onSubmit} encType="multipart/form-data">
        {isBusiness ?
          <input type="hidden" name="formType" value="business" />
          :
          <input type="hidden" name="formType" value="freelancer" />
        }
        <div className={`${showSecondPage ? "is--hidden" : ""}`}>
          {isBusiness ?
            <p className="ac-contact-modal__headline">Werdem Sie jetzt Auch Cool!</p>
          :
            <p className="ac-contact-modal__headline">Werde jetzt Auch Cool!</p>
          }
          {isBusiness ? 
              <label>
                Firmname
                <input
                  type="text"
                  name="firmname"
                  value={firmname}
                  onChange={(e) => setFirmname(e.target.value)}
                />
              </label>
          : ""}
          <div className="grid-2">
            <label>
              Vorname
              <input
                type="text"
                name="firstname"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </label>
            <label>
              Nachname
              <input
                type="text"
                name="lastname"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </label>
          </div>
          <label>
            E-Mail
            <input
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label>
            Telefonnummer
            <input
              type="text"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </label>
          <label>
            Website
            <input
              type="text"
              name="website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </label>
          {isBusiness ? 
          <label>
            Ihre Nachricht
            <textarea className="ac-input" name="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
          </label>
          : 
            <label className="ac-select">
              Tätigkeit als Freelancer
              <select name="skill">
                <option value="Designer">Designer</option>
                <option value="Web-Developer">Web-Developer</option>
              </select>
            </label>
          }
          
          {isBusiness ? 
            <button className="ac-btn red">Abschicken</button>
          : 
          < span className="ac-contact-modal__pagination">1/<span>2</span> <button type="button" onClick={() => setShowSecondPage(true)}>Next</button></span>
          }
          
        </div>
        {!isBusiness ? 
        <div className={showSecondPage ? "ac-contact-modal__content-2 show" : "ac-contact-modal__content-2"}>
          <p className="ac-contact-modal__subline">CV und Arbeitsproben</p>
          <p className="ac-contact-modal__subline-text">Bitte stelle uns deinen CV und mind. eine Arbeitsprobe zur Verfügung. Egal ob Portfolio Upload, Link oder Veweis zu einer Website.</p>
          <label className="ac-file-input">
            CV Upload
            <input
              type="file"
              name="files[]"
              name="cv"
              onChange={(e) => setCv( e.target.files )}
            />
          </label>
          <label className="ac-file-input">
            Portfolio Upload
            <input
              type="file"
              name="portfolio"
              onChange={(e) => setPortfolio(e.target.files)}
            />
          </label>
          <label>
            Link etc.
            <input
              type="text"
              name="link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </label>
          <button className="ac-btn red">Abschicken</button>
          <span className="ac-contact-modal__pagination"><button type="button" onClick={() => setShowSecondPage(false)}>Back</button> 2/2</span>
        </div>
      : ""}
      </form>
    </div>
  )
}