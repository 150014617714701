import React, { useEffect, useState } from "react";

import auchCoolLogo from './../assets/images/logos/auchcool.svg';
import wsepicLogo from './../assets/images/logos/wsepic.svg';

var scrollPosition = 0;

export default function Navigation({isOpen, setIsOpen}) {
    //const [scrollPosition, setSrollPosition] = useState(0);
    const [hide, setHide] = useState( false );

    const handleScroll = () => {
        if ((document.body.getBoundingClientRect()).top >= 0 || (document.body.getBoundingClientRect()).top > scrollPosition) {
            setHide( false );
        } else {
            setHide( true );
        }
        scrollPosition = (document.body.getBoundingClientRect()).top;
    };

    const handleClick = (e) => {
        setIsOpen(false);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return(
        <nav className={`ac-navigation${hide ? " is--hide" : ""}${isOpen ? " is--open" : ""}`}>
            <span className="ac-navigation__logo">
                <img src={auchCoolLogo} alt="" title="" />
                <span><img src={wsepicLogo} alt="" title="" /></span>
            </span>
            <button className="ac-navigation__btn" onClick={() => setIsOpen(!isOpen)} >
                Menu
                <span className="ac-navigation__icon">
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </span>
            </button>
            <div className="ac-navigation__wrapper">
                <ol>
                    <li>
                        <a href="#home" onClick={() => setIsOpen(false)}>Home</a>
                    </li>
                    <li>
                        <a href="#faq" title="FAQ" onClick={() => setIsOpen(false)}>Faq</a>
                    </li>
                    <li>
                        <a href="#anmelden" title="Anmelden" onClick={() => setIsOpen(false)}>Anmelden</a>
                    </li>
                    <li>
                        <a href="#contact" title="Kontakt" onClick={() => setIsOpen(false)}>Kontakt</a>
                    </li>
                </ol>
            </div>
        </nav>
    )
}